<template>
  <div :id="uploaderId">
    <div
      class="dz-message needsclick"
      style="text-align: center; cursor: pointer;"
    >
      <div class="mb-3">
        <i class="display-4 text-muted bx bx-cloud-upload" />
      </div>
      <h5> {{ title }} </h5>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import Dropzone from 'dropzone'
import { axios } from '@/axios'
import { getToken } from '@/storage'

export default {
  name: 'FileUploader',
  props: {
    title: {
      type: String,
      default: 'Arraste os arquivos aqui ou clique para selecionar'
    },
    accepted: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    },
    on: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const uploaderId = `uploader_${Math.random().toString().substring(3, 8)}`

    const setupUploader = (options = {}) => {
      const { baseURL } = axios.defaults
      const uploadURL = `${baseURL}/media`

      new Dropzone(`div#${uploaderId}`, {
        paramName: 'media',
        uploadMultiple: true,
        disablePreviews: true,
        acceptedFiles: props.accepted,
        url: uploadURL,
        headers: {
          authorization: `Bearer ${getToken()}`
        },
        ...options,
        init: function () {
          for (const [evt, callback] of Object.entries(props.on)) {
            this.on(evt, callback)
          }
        }
      })
    }

    onMounted(() => {
      setupUploader(props.options)
    })

    return {
      uploaderId
    }
  }
}
</script>
